import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ISlideModel } from '../../../core/models/slide.model';

@Injectable({
  providedIn: 'root',
})
export class SlideService {
  constructor(private http: HttpClient) {}

  SaveSlide(
    slide: ISlideModel,
    courseId: number,
  ): Observable<{ slideId: number }> {
    return this.http
      .post<{
        slideId: number;
      }>(
        `${environment.backendApiUrl}/SaveSlide?courseId=${courseId}&code=${environment.apiKey}`,
        { slide: slide },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveSlides(
    slides: ISlideModel[],
    courseId: number,
  ): Observable<{ slides: number[] }> {
    return this.http
      .post<{
        slides: number[];
      }>(
        `${environment.backendApiUrl}/SaveSlides?courseId=${courseId}&code=${environment.apiKey}`,
        { slides: slides },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DuplicateSlide(slideId: number, courseVersionId: number): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/DuplicateSlide?code=${environment.apiKey}`,
        { courseVersionId, slideId },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteSlide(slide: number): Observable<object> {
    return this.http
      .delete(
        `${environment.backendApiUrl}/DeleteSlide?slideId=${slide}&code=${environment.apiKey}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}

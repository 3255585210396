import { Action } from '@ngrx/store';
import { IAssetContentDto, IAssetDto } from '../../../core/dto/asset-dto.model';
import { IBlobAssetDto } from '../../../core/dto/blob-asset-dto.model';
import { ISlideModel } from '../../../core/models/slide.model';

export enum ActionTypes {
  SET_CURRENT_SLIDE = '[Slide] Set current Slide',
  START_SLIDE = '[Slide] Start Slide',
  OPEN_SLIDE = '[Slide] Open Slide',
  CLOSE_SLIDE = '[Slide] Close Slide',
  SET_SLIDE_POSITION = '[Slide] Set Slide Position',
  SAVE_SLIDE = '[Slide] Save Slide',
  SAVE_SLIDES = '[Slide] Save Slides',
  SAVE_SLIDE_ASSETS = '[Slide] Save Slide Assets',
  SAVE_TEMPORARY_SLIDE_ASSET = '[Slide] Save temporary Slide Asset',
  SAVE_TEMPORARY_SLIDE_ASSET_PROGRESS = '[Slide] Save temporary Slide Asset Progress',
  SAVE_TEMPORARY_SLIDE_ASSET_URL_UPDATE = '[Slide] Save temporary Slide Asset Url Update',
  SAVE_SLIDE_THUMBNAILS = '[Slide] Save Thumbnails',
  SAVE_SLIDE_SUCCESS = '[Slide] Save Slide Success',
  SAVE_SLIDES_SUCCESS = '[Slide] Save Slides Success',
  SAVE_SLIDE_ASSETS_SUCCESS = '[Slide] Save Slide Assets Success',
  SAVE_SLIDE_FAILURE = '[Slide] Save Slide Failure',
  SAVE_SLIDE_ASSETS_FAILURE = '[Slide] Save Slide Assets Failure',

  // For reload purposes after having saved new assets (id's need to be propagated back)
  LOAD_SLIDE_ASSETS_SUCCESS = '[Slide] Load Slide Assets Success',

  UPDATE_SLIDES = '[Slide] Update Slides',
  UPDATE_SLIDES_SUCCESS = '[Slide] Update Slides Success',
  UPDATE_SLIDES_FAILURE = '[Slide] Update Slides Failure',
  DELETE_SLIDE = '[Slide] Delete Slide',
  DELETE_SLIDE_SUCCESS = '[Slide] Delete Slide Success',
  DELETE_SLIDE_FAILURE = '[Slide] Delete Slide Failure',
  SET_LOADING_ACTIVE = '[Slide] Set loading Slide active',
  SET_LOADING_INACTIVE = '[Slide] Set loading Slide inactive',
  DUPLICATE_SLIDE = '[Slide] Duplicate Slide',
  DUPLICATE_SLIDE_SUCCESS = '[Slide] Duplicate Slide Success',
  DUPLICATE_SLIDE_FAILURE = '[Slide] Duplicate Slide Failure',
  RESET_STATE_CHANGE = '[Slide] Reset State Change',
  SET_SLIDE_TO_COPY = '[Slide] Set Slide to Copy',
  CLEAR_SLIDE_TO_COPY = '[Slide] Clear Slide to Copy',
  SELECT_CATEGORY = '[Slide] Select Category',
  CLEAR_CATEGORY = '[Slide] Clear Category',
}

export class ResetStateAction implements Action {
  readonly type = ActionTypes.RESET_STATE_CHANGE;
}

export class StartSlideAction implements Action {
  readonly type = ActionTypes.START_SLIDE;
}

export class OpenSlideAction implements Action {
  readonly type = ActionTypes.OPEN_SLIDE;
  constructor(public payload: { position: number }) {}
}

export class CloseSlideAction implements Action {
  readonly type = ActionTypes.CLOSE_SLIDE;
}

export class SetCurrentSlideAction implements Action {
  readonly type = ActionTypes.SET_CURRENT_SLIDE;
  constructor(public payload: { slide: ISlideModel }) {}
}

export class SetSlidePositionAction implements Action {
  readonly type = ActionTypes.SET_SLIDE_POSITION;
  constructor(public position: number) {}
}

export class SaveSlideAction implements Action {
  readonly type = ActionTypes.SAVE_SLIDE;
  constructor(
    public slide: ISlideModel,
    public courseId: number,
    public versionNumber: number,
  ) {}
}

export class SaveSlideSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_SLIDE_SUCCESS;
  constructor(
    public courseId: number,
    public versionNumber: number,
    public slideId: number,
  ) {}
}

export class SaveSlideFailureAction implements Action {
  readonly type = ActionTypes.SAVE_SLIDE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SaveSlidesAction implements Action {
  readonly type = ActionTypes.SAVE_SLIDES;
  constructor(
    public courseId: number,
    public versionNumber: number,
    public slideId: number,
    public newSlides: ISlideModel[],
    public newAssets: IAssetContentDto[][],
    public changedAssets: IAssetDto[],
  ) {}
}

export class SaveSlidesSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_SLIDES_SUCCESS;
  constructor(
    public courseId: number,
    public versionNumber: number,
    public slideId: number,
    public newSlideIds: number[],
    public newAssets: IAssetContentDto[][],
    public changedAssets: IAssetDto[],
  ) {}
}

export class SaveSlideAssetsAction implements Action {
  readonly type = ActionTypes.SAVE_SLIDE_ASSETS;
  constructor(
    public payload: {
      assets: IAssetDto[];
    },
  ) {}
}

export class SaveSlideAssetsSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_SLIDE_ASSETS_SUCCESS;
  constructor(public payload: { courseId: number }) {}
}

export class SaveSlideAssetsFailureAction implements Action {
  readonly type = ActionTypes.SAVE_SLIDE_ASSETS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSlideAssetsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SLIDE_ASSETS_SUCCESS;
  constructor(public payload: { assets: IAssetDto[] }) {}
}

export class SaveTemporarySlideAssetAction implements Action {
  readonly type = ActionTypes.SAVE_TEMPORARY_SLIDE_ASSET;
  constructor(
    public payload: {
      asset: IBlobAssetDto;
      courseId: number;
    },
  ) {}
}

export class SaveTemporarySlideAssetProgressAction implements Action {
  readonly type = ActionTypes.SAVE_TEMPORARY_SLIDE_ASSET_PROGRESS;
  constructor(
    public payload: {
      progress: number;
      placeholderCode: string;
    },
  ) {}
}

export class SaveTemporarySlideAssetUrlUpdateAction implements Action {
  readonly type = ActionTypes.SAVE_TEMPORARY_SLIDE_ASSET_URL_UPDATE;
  constructor(
    public payload: {
      urls: string[];
      placeholderCode: string;
    },
  ) {}
}

export class SaveSlideThumbnailsAction implements Action {
  readonly type = ActionTypes.SAVE_SLIDE_THUMBNAILS;
  constructor(
    public courseId: number,
    public slideId: number,
    public thumbnail: string,
  ) {}
}

export class UpdateSlidesAction implements Action {
  readonly type = ActionTypes.UPDATE_SLIDES;
  constructor(
    public payload: {
      slides: ISlideModel[];
      courseId: number;
      versionNumber: number;
    },
  ) {}
}

export class UpdateSlidesSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SLIDES_SUCCESS;
  constructor(public payload: { courseId: number; versionNumber: number }) {}
}

export class UpdateSlidesFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_SLIDES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSlideAction implements Action {
  readonly type = ActionTypes.DELETE_SLIDE;
  constructor(
    public payload: { slide: number; courseId: number; versionNumber: number },
  ) {}
}

export class DeleteSlideSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SLIDE_SUCCESS;
  constructor(public payload: { courseId: number; versionNumber: number }) {}
}

export class DeleteSlideFailureAction implements Action {
  readonly type = ActionTypes.DELETE_SLIDE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DuplicateSlideAction implements Action {
  readonly type = ActionTypes.DUPLICATE_SLIDE;
  constructor(
    public slideId: number,
    public courseId: number,
    public courseVersionId: number,
    public versionNumber: number,
  ) {}
}

export class DuplicateSlideSuccessAction implements Action {
  readonly type = ActionTypes.DUPLICATE_SLIDE_SUCCESS;
  constructor(public payload: { courseId: number; versionNumber: number }) {}
}

export class DuplicateSlideFailureAction implements Action {
  readonly type = ActionTypes.DUPLICATE_SLIDE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SetSlideToCopyAction implements Action {
  readonly type = ActionTypes.SET_SLIDE_TO_COPY;
  constructor(public slide: ISlideModel) {}
}

export class ClearSlideToCopyAction implements Action {
  readonly type = ActionTypes.CLEAR_SLIDE_TO_COPY;
}

export class SelectCategoryAction implements Action {
  readonly type = ActionTypes.SELECT_CATEGORY;
  constructor(public payload: { selectedCategory: string }) {}
}

export class ClearCategoryAction implements Action {
  readonly type = ActionTypes.CLEAR_CATEGORY;
}

export type Actions =
  | SetCurrentSlideAction
  | SetSlidePositionAction
  | SaveSlideAction
  | SaveSlideAssetsAction
  | LoadSlideAssetsSuccessAction
  | SaveTemporarySlideAssetAction
  | SaveTemporarySlideAssetProgressAction
  | SaveTemporarySlideAssetUrlUpdateAction
  | SaveSlideSuccessAction
  | SaveSlideFailureAction
  | SaveSlideThumbnailsAction
  | UpdateSlidesAction
  | UpdateSlidesSuccessAction
  | UpdateSlidesFailureAction
  | DeleteSlideAction
  | DeleteSlideSuccessAction
  | DeleteSlideFailureAction
  | StartSlideAction
  | OpenSlideAction
  | CloseSlideAction
  | DuplicateSlideAction
  | DuplicateSlideSuccessAction
  | DuplicateSlideFailureAction
  | SaveSlideAssetsSuccessAction
  | SaveSlideAssetsFailureAction
  | ResetStateAction
  | SetSlideToCopyAction
  | ClearSlideToCopyAction
  | SelectCategoryAction
  | ClearCategoryAction;

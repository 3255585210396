import { Action } from '@ngrx/store';
import { IMediaAssetDto } from '../../../core/dto/media-asset-dto.model';
import { IMediaBlobAssetsDto } from '../../../core/dto/media-blob-assets-dto.model';

export enum ActionTypes {
  RESET_ERROR_STATE = '[Media] Reset State',

  SAVE_TEMPORARY_MEDIA_ASSETS_UPDATE = '[Media] Save temporary Media Asset Url Update',
  SAVE_TEMPORARY_MEDIA_ASSET_PROGRESS = '[Slide] Save temporary Media Asset Progress',
  SAVE_TEMPORARY_MEDIA_ASSET = '[Media] Save temporary Media Asset',

  DELETE_TEMPORARY_MEDIA_ASSET = '[Media] Delete temporary Media Assets',
  DELETE_TEMPORARY_MEDIA_ASSET_SUCESS = '[Media] Delete temporary Media Assets Success',
  DELETE_TEMPORARY_MEDIA_ASSET_FAILURE = '[Media] Delete temporary Media Assets Failure',

  SAVE_MEDIA_ASSETS = '[Media] Save Media Assets',
  SAVE_MEDIA_ASSETS_SUCESS = '[Media] Save Media Assets Success',
  SAVE_MEDIA_ASSETS_FAILURE = '[Media] Save Media Assets Failure',

  DELETE_MEDIA_ASSET = '[Media] Delete Media Asset',
  DELETE_MEDIA_ASSET_SUCESS = '[Media] Delete Media Asset Success',
  DELETE_MEDIA_ASSET_FAILURE = '[Media] Delete Media Asset Failure',

  LOAD_MEDIA_ASSETS = '[Media] Load Media Assets',
  LOAD_MEDIA_ASSETS_SUCCESS = '[Media] Load Media Assets Success',
  LOAD_MEDIA_ASSETS_FAILURE = '[Media] Load Media Assets Failure',

  LOAD_MEDIA_EDITORS = '[Media] Load Editors',
  LOAD_MEDIA_EDITORS_SUCCESS = '[Media] Load Editors Success',
  LOAD_MEDIA_EDITORS_FAILURE = '[Media] Load Editors Failure',

  CLEAR_TEMPORARY_MEDIA_ASSETS_URL_UPDATE = '[Media] Clear temporary Media Asset Url Update',
  CLEAR_MESSAGES = '[Media] Clear Messages',
}

export class MediaAssetResetStateAction implements Action {
  readonly type = ActionTypes.RESET_ERROR_STATE;
}

// Temporary media assets
export class SaveTemporaryMediaAssetAction implements Action {
  readonly type = ActionTypes.SAVE_TEMPORARY_MEDIA_ASSET;
  constructor(
    public payload: {
      asset: IMediaBlobAssetsDto;
    },
  ) {}
}

export class SaveTemporaryMediaAssetProgressAction implements Action {
  readonly type = ActionTypes.SAVE_TEMPORARY_MEDIA_ASSET_PROGRESS;
  constructor(
    public payload: {
      progress: number;
    },
  ) {}
}

export class SaveTemporaryMediaAssetsUpdateAction implements Action {
  readonly type = ActionTypes.SAVE_TEMPORARY_MEDIA_ASSETS_UPDATE;
  constructor(
    public payload: {
      assets: IMediaAssetDto[];
    },
  ) {}
}

export class DeleteTemporaryMediaAssetAction implements Action {
  readonly type = ActionTypes.DELETE_TEMPORARY_MEDIA_ASSET;
  constructor(
    public payload: {
      mediaAssetId: number;
    },
  ) {}
}

export class DeleteTemporaryMediaAssetSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_TEMPORARY_MEDIA_ASSET_SUCESS;
}

export class DeleteTemporaryMediaAssetFailureAction implements Action {
  readonly type = ActionTypes.DELETE_TEMPORARY_MEDIA_ASSET_FAILURE;
  constructor(public payload: { error: string }) {}
}

// Load Media Assets
export class LoadMediaAssetsAction implements Action {
  readonly type = ActionTypes.LOAD_MEDIA_ASSETS;
  constructor(
    public payload: {
      mediaType: string;
      searchText: string;
      categoryId: number;
      timeFrame: number;
      editorId: string;
      privateOnly: boolean;
      sortBy: string;
      sortDir: string;
    },
  ) {}
}

export class LoadMediaAssetsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MEDIA_ASSETS_SUCCESS;
  constructor(public assets: IMediaAssetDto[]) {}
}

export class LoadMediaAssetsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_MEDIA_ASSETS_FAILURE;
  constructor(public payload: { error: string }) {}
}

// Save media assets
export class SaveMediaAssetsAction implements Action {
  readonly type = ActionTypes.SAVE_MEDIA_ASSETS;
  constructor(public assets: IMediaAssetDto[]) {}
}

export class SaveMediaAssetsSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_MEDIA_ASSETS_SUCESS;
}

export class SaveMediaAssetsFailureAction implements Action {
  readonly type = ActionTypes.SAVE_MEDIA_ASSETS_FAILURE;
  constructor(public payload: { error: string }) {}
}

// Delete media assets
export class DeleteMediaAssetAction implements Action {
  readonly type = ActionTypes.DELETE_MEDIA_ASSET;
  constructor(
    public payload: {
      id: number;
    },
  ) {}
}

export class DeleteMediaAssetSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_MEDIA_ASSET_SUCESS;
}

export class DeleteMediaAssetFailureAction implements Action {
  readonly type = ActionTypes.DELETE_MEDIA_ASSET_FAILURE;
  constructor(public payload: { error: string }) {}
}

// Load Media Editors
export class LoadMediaEditorsAction implements Action {
  readonly type = ActionTypes.LOAD_MEDIA_EDITORS;
}

export class LoadMediaEditorsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MEDIA_EDITORS_SUCCESS;
  constructor(public editors: string[]) {}
}

export class LoadMediaEditorsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_MEDIA_EDITORS_FAILURE;
  constructor(public payload: { error: string }) {}
}

// Clear Media State
export class ClearTemporaryMediaAssetUrlUpdateAction implements Action {
  readonly type = ActionTypes.CLEAR_TEMPORARY_MEDIA_ASSETS_URL_UPDATE;
}
export class ClearMessages implements Action {
  readonly type = ActionTypes.CLEAR_MESSAGES;
}

export type Actions =
  | MediaAssetResetStateAction
  | SaveTemporaryMediaAssetAction
  | SaveTemporaryMediaAssetsUpdateAction
  | SaveTemporaryMediaAssetProgressAction
  | DeleteTemporaryMediaAssetAction
  | DeleteTemporaryMediaAssetSuccessAction
  | DeleteTemporaryMediaAssetFailureAction
  | SaveMediaAssetsAction
  | SaveMediaAssetsSuccessAction
  | SaveMediaAssetsFailureAction
  | DeleteMediaAssetAction
  | DeleteMediaAssetSuccessAction
  | DeleteMediaAssetFailureAction
  | LoadMediaAssetsAction
  | LoadMediaAssetsSuccessAction
  | LoadMediaAssetsFailureAction
  | LoadMediaEditorsAction
  | LoadMediaEditorsSuccessAction
  | LoadMediaEditorsFailureAction
  | ClearTemporaryMediaAssetUrlUpdateAction
  | ClearMessages;
